import React, {useEffect, useRef, useState} from "react"
import {Click, Wrap} from "@prisma/client"
import {INTERSTITIAL_DELAY, WrapIncludes} from "@wildeye/shared"
import {useLazyGetClickQuery, useLazyGetWrapQuery} from "../../../store/apps/api/warmlink"
import {useRouter} from "next/router"
import InterContent2 from "./interContent2"

export interface InterstitialProps {
  type?: 'warm' | 'wrap'
  wrap?: WrapIncludes | null,
  skip?: () => void,
  visit?: () => void,
  countdown?: number,
}

export default function Interstitial({type}: InterstitialProps) {
  const router = useRouter()
  const {query} = router
  const stop = query.stop === ''
  const {slugs} = router.query as { slugs: string[] | undefined }
  const code = slugs?.[0]
  const isWrap = type == 'wrap'
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [trigger, {data, isLoading, error}] = isWrap ? useLazyGetWrapQuery() : useLazyGetClickQuery()
  const [wrap, setWrap] = useState<WrapIncludes | null>(null)
  const [initialized, setInitialized] = useState(false)
  const [isClient, setIsClient] = useState(false)
  const [countdown, setCountdown] = useState<number | undefined>(undefined)
  const timerRef = useRef<number | null>(null)
  const intervalRef = useRef<number | null>(null) // Ref for countdown interval

  useEffect(() => {
    setIsClient(true)
  }, [])

  // This loads the wrap/warm data when code is valid
  useEffect(() => {
    if (slugs) {
      trigger(slugs.join('/'))
    }
  }, [slugs, trigger])

  // Delay and redirect when wrap loaded
  useEffect(() => {
    const resetCountdownAndTimers = () => {
      if (timerRef.current) window.clearTimeout(timerRef.current)
      if (intervalRef.current) window.clearInterval(intervalRef.current)
      setCountdown(undefined) // Reset countdown state
    }

    const handleRedirect = async () => {
      try {
        if (initialized && data && !isLoading && !error) {
          const wrap = data as unknown as Wrap
          setWrap(wrap)
          const click = data as unknown as Click
          const link = isWrap ? wrap.link : click.affiliate_link
          const delay = isWrap ? wrap.delay : INTERSTITIAL_DELAY
          /**
           * Start internval countdown timer
           */
          const delayInSeconds = Math.ceil(delay / 1000)
          setCountdown(delayInSeconds)
          if (!stop) intervalRef.current = window.setInterval(() => {
            setCountdown(prev => {
              if (prev! > 1) {
                return prev! - 1
              } else {
                // Stop countdown when it reaches 0
                if (intervalRef.current) window.clearInterval(intervalRef.current)
                return 0
              }
            })
          }, 1000)
          if (!stop) {
            /**
             * Begin timeout that triggers redirect
             */
            // Replace the current entry in the history stack with the home page URL so that when
            // user goes backwards they end up at Warmlink's home page
            window.history.replaceState(null, '', '/')
            window.history.pushState(null, '', '/')
            // wait for a short time before redirecting to desired location
            timerRef.current = window.setTimeout(async () => {
              // await router.replace(link)
              if (intervalRef.current) window.clearInterval(intervalRef.current)
              window.location.href = link
            }, delay)
          } else if (timerRef.current) window.clearTimeout(timerRef.current)
        }
      } catch (err) {
        console.error("Error during navigation:", err)
      }
      return
    }

    handleRedirect().then(() => {
    })

    // Cleanup function to clear interval and timeout
    return resetCountdownAndTimers
  }, [data, isLoading, error, code, router])

  // Redirect to home if code is bad
  useEffect(() => {
    if (!isLoading && (error || !data)) {
      if (initialized) {
        if (!stop) handleSkip()
      }
    }
  }, [error, isLoading, data, router, initialized])

// Set initialized when we get valid code
  useEffect(() => {
    if (code !== undefined) {
      setInitialized(true)
    }
  }, [code, query, router])

  function handleSkip() {
    window.location.href = wrap?.link || '/'
  }

  function handleVisitWarmlink() {
    if (timerRef.current !== null) {
      window.clearTimeout(timerRef.current)
      if (intervalRef.current !== null) {
        window.clearInterval(intervalRef.current) // Clear the interval on manual visit
      }
      router.push(process.env.NEXT_PUBLIC_APP + '').then(() => {
      })
    }
  }

  if (!isClient) {
    return null
  }

  const click = data as unknown as Click
  const display = stop || isWrap || (data && !isLoading && click.offer_id !== 'warmlink')
  // console.log(`display:${display} code:${code} stop:${stop} data:${JSON.stringify(data)} isLoading:${isLoading}`)
  if (!display) return null

  return (<InterContent2 wrap={wrap} visit={handleVisitWarmlink} skip={handleSkip} countdown={countdown}/>)
}
