import * as React from "react"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import {InterstitialProps} from "./Interstitial"
import {Theme, useTheme} from "@mui/material/styles"

// ✅🔥
// const header = 'Go Dawg Go!'
const header = `Win Business Win!`
const HeaderColor = '#333'
const subheader1 = '🔥 Business success with world class SaaS 🔥'
const SubheaderColor = '#555'
const subheader2 = '' // '🔥 Start earning $1 million/year passive income for life'
const JoinColor = '#AAA'
const WarmlinkButtonColor = '#8B4513' // SaddleBrown
const WarmlinkButtonHoverColor = '#ad5517'
const WarmlinkButtonActiveColor = '#c7621c'
const SkipButtonColor = '#B8860B' // DarkGoldenrod
const SkipButtonHoverColor = '#DAA520' // Goldenrod
const SkipButtonActiveColor = '#f0b522'

export default function InterContent2(props: InterstitialProps) {
  const {skip, visit, countdown} = props
  const theme = useTheme()

  return (
    <Box sx={{
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center', // Vertically centers the content
      alignItems: 'center', // Horizontally centers the content
      minHeight: '100vh', // Ensures the Box takes the full height of the viewport
      backgroundColor: 'white',
    }}>
      <Grid container spacing={0.5}>
        {header && <Grid item xs={12} sx={{mb: {xs: '0.6vw', md: '0.6vw'}}}>
          <Header text={header} theme={theme}/>
        </Grid>}
        {subheader1 && <Grid item xs={12} sx={{mb: 0}}>
          <Subheader text={subheader1} theme={theme}/>
        </Grid>}
        {subheader2 && <Grid item xs={12} sx={{mt: 0}}>
          <Subheader text={subheader2} theme={theme}/>
        </Grid>}
        <Grid item xs={12} sx={{my: {xs: '4vw', md: '3vw'}}}>
          <Warmlink/>
        </Grid>
        <Grid item xs={12} sx={{mb: {xs: '3vw', md: '1vw'}}}>
          <Countdown countdown={countdown} skip={skip} visit={visit}/>
        </Grid>
      </Grid>
    </Box>
  )
}

type SubheaderType = {
  text: string
  theme?: Theme
}

function Header({text}: SubheaderType) {
  return (
    <Typography
      sx={{
        fontWeight: 900,
        fontSize: {xs: '8vw', md: '7vw'},
        color: HeaderColor,
        lineHeight: 1.1,
        width: '90%',
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
        whiteSpace: 'pre-line',
      }}>
      {text}
    </Typography>

  )
}

function Subheader({text}: SubheaderType) {
  return (
    <Typography sx={{
      fontSize: {
        xs: '4vw',
        md: '3vw',
      },
      color: SubheaderColor,
      lineHeight: 1.2,
      whiteSpace: 'pre-line',
      mt: 2,
      width: '90%',       // Constrain width to 80% of the parent container
      marginLeft: 'auto',       // Center the component horizontally
      marginRight: 'auto',      // Center the component horizontally
      textAlign: 'center', // Optional: center-align the text
    }}>
      {text}
    </Typography>
  )
}

function Warmlink() {
  return (<>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column', // Keeps "join" text on top of the logo
          alignItems: 'center', // Horizontally centers the content
          justifyContent: 'center',
          width: '100%',
          textAlign: 'center' // Centers text inside the Box
        }}
      >
        {/*<Typography sx={{mb: 0, fontSize: {xs: '3vw', md: '2vw'}}}><b>join</b></Typography>*/}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: '70%',
            textAlign: 'center'
          }}
        >
          <Typography sx={{
            color: JoinColor,
            fontSize: {xs: '3vw', md: '2vw'},
            width: '8vw'
          }}><i>join</i></Typography>
          <img
            src='https://storage.googleapis.com/warmlinkprod/warmlink.io-logo.svg'
            alt='warmlink logo'
            style={{width: '60vw', maxWidth: '50%'}} // Resizes proportionally with max limit
          />
          <img
            src='/images/iconify-svg/svg-spinners--bars-fade.svg'
            alt="spinner"
            style={{
              width: '7vw',
              maxWidth: '50%',
              marginLeft: 2,
              marginBottom: -4
            }} // Resizes proportionally with max limit
          />
          {/*<Box sx={{width: '9vw'}}></Box>*/}
        </Box>
      </Box>
    </>
  )
}

function Countdown(props: InterstitialProps) {
  const {skip, visit, countdown} = props
  return (
    <Box sx={{
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '3vw',
      textAlign: 'center'
    }}>
      <Button
        //color={WarmlinkButtonColor}
        size='medium'
        variant='contained'
        onClick={visit}
        sx={{
          backgroundColor: WarmlinkButtonColor,
          fontSize: {xs: '3vw', md: '2vw'},
          width: {xs: '22vw', md: '17vw'},
          height: {xs: '5vw', md: '3vw'},
          '&:hover': {
            backgroundColor: WarmlinkButtonHoverColor,
          },
          '&:active': {
            backgroundColor: WarmlinkButtonActiveColor,
          },
        }}>
        Warmlink
      </Button>
      <Typography variant='h2'
                  sx={{
                    width: {xs: '10vw', md: '5vw'},
                    height: {xs: '5.25vw', md: '3vw'},
                    fontSize: {xs: '3vw', md: '2.5vw'},
                    backgroundColor: countdown === undefined ? 'transparent' : '#0001',
                    padding: 0,
                    borderRadius: '10px',
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
        {countdown}
      </Typography>
      <Button
        size='small'
        variant='contained'
        onClick={skip}
        sx={{
          backgroundColor: SkipButtonColor,
          fontSize: {xs: '3vw', md: '2vw'},
          width: {xs: '22vw', md: '17vw'},
          height: {xs: '5vw', md: '3vw'},
          '&:hover': {
            backgroundColor: SkipButtonHoverColor,
          },
          '&:active': {
            backgroundColor: SkipButtonActiveColor,
          },
        }}>
        Skip
      </Button>
    </Box>
  )
}

